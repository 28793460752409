footer{
    position:relative;
    width:100%;
    p {padding-left: 20px;}
  }
@media screen and (max-width: 450px) {
  footer{
    position:relative;
    width:100%;
    bottom:2px;
    border: none;
  }
}