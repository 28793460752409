.row {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  box-sizing: border-box;
}

/* Create four equal columns that sits next to each other */
.column {
  flex: 25%;
  max-width: 25%;
  padding: 0 4px;
  box-sizing: border-box;
}

.column img {
  margin-top: 8px;
  vertical-align: middle;
  width: 100%;
  box-sizing: border-box;
}


*{
  font-family: 'Satoshi', sans-serif;
  color: #fff;
}
::-webkit-scrollbar {
  width: 12px;
}

#project-link{
  position: relative;
  display: block;
  margin-top: 20%;
}
/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #1f2937;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(134, 239, 172), rgb(59, 130, 246), rgb(147, 51, 234));
}
.about{
  float:right;
  width: 70%;
}
.icon{
  margin-right: 2%;
}
#map{
  width: 100%;
}
.centered #centered-top {
  display:flow-root;
}

section{
  width: 100%;
  display: block;
}

html{
  background: rgb(19,13,104);
background: radial-gradient(circle, rgba(19,13,104,1) 0%, rgba(9,9,121,1) 16%, rgba(0,0,0,1) 100%);
}
#portrait{
  width: 20%;
  float:left;
  padding-right: 5%;
}
.App {
  text-align: center;
}
.centered{
  position: relative;
  display: block;
  padding: 5%;
}
.container{
  width: 50%;
  float:left;
  height: 100%;
  padding-top: 20px;
}
.left{
  left: 0;
}
.right {
  float:right;
  width: 40%;
  overflow: hidden;
}
/* Center the content horizontally and vertically */
/* .centered {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
} */
.main {
  overflow: auto;
}
.image {
  width: 40%;
  position: relative;
}

.overlay {
  position: relative;
  width: 40%;
  top: 40px;
  left: -20px;
  z-index: 1; /* Higher z-index to place it on top */
  /* Your overlay image styles */
  overflow: hidden;
}

a.cta{
  border: #fff solid 1px;
  background-color: #fff;
  font-size: 20pt;
  margin-top: 2%;
  color: #000;
  display: flex;
  align-items: center;
  padding-left: 2%;
  padding-right: 2%;
  text-decoration: none;
  width: 100px;
}
a.cta span{
  color: #000;
  font-size: 18pt;
  display: table;
  margin: 0 auto;
  line-height: 25px;
  padding-left: 5%;
  justify-content: space-between;
}
a.cta:hover{
  cursor: pointer;
  background-color:#000 ;
  color: rgb(0, 255, 34);
  border: rgb(0, 255, 34), 1px, solid;

  span { /*change css of span when button is hovered*/
    color: rgb(0, 255, 34);
  }
}

.link:hover {
  color: rgb(0, 255, 34);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #ffd000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 450px) {
  .about{
    width:50%;
  }
  #portrait{
    width: 40%;
  }
  
  .main{
    padding: 5%;
  }
  .container{
    /* margin: 50px; */
    width: 100%;
    /* float:left; */
    height: 100%;
    position: relative;
    z-index: unset;
    top: 0;
    overflow-y:visible;
    padding-top: 20px;
  }
  .left{
    width: 100%;
    position: relative;
  }
  .right {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;

    .centered img {
      position:relative;
      left: 0;
    }
  }
}
/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .column {
    flex: 50%;
    max-width: 50%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    flex: 100%;
    max-width: 100%;
  }
}